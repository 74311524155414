module.exports = [
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/people_strong.svg`,
    linkUrl: "https://ampersand.peoplestrong.com/",
  },
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/hubble_star.svg`,
    linkUrl: "https://hubblestar.hubblehox.ai/#/vgos-internal",
  }
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/oracle.svg`,
  //   linkUrl: "https://login-ewzj-test-saasfaprod1.fa.ocs.oraclecloud.com/",
  // },
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/student_kare.svg`,
  //   linkUrl: "/",
  // },
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/elevate.svg`,
  //   linkUrl: "/",
  // },
];
